import * as React from "react"
import { Link } from "gatsby"

const Profile = data => {
  data = data.data
  return (
    <div className="profile max-650">
      <div className="header pb20">
        <p className="op-50">{data?.category}</p>
        <h1 className="mt80 h3 m0 mb20 max-450">{data?.title}</h1>
        <div className="bb1 b-grey" />
      </div>
      <div className="content">
        {( data.pullQuote ?
          <p className="h3 m0 mt20">{data.pullQuote}</p> :
          ''
        )}
        {( data.content ?
          <div className="medium mt40 mb40 pb20 break-space" dangerouslySetInnerHTML={{ __html: data?.content }} />
          :''
        )}
        <div className="bb1 b-grey" />
      </div>
      <div className="pt20 b-grey flex mb40">
        <Link to="/journal" className="link medium ">
          Back to Journal <span className="ml20 arrow" />
        </Link>
      </div>
    </div>
  )
}

export default Profile
