import * as React from "react"
import Layout from "../components/layout"
import PostImage from "../components/postImage"
import PostContent from "../components/postContent"
import Seo from "../components/seo"
import Cta from "../components/cta"
import { graphql } from "gatsby"

const Member = ({ data }) => {
  data = data.post
  return (
    <Layout>
      <div className="p40 flex m-wrap m-reverse">
        <div className="w-50 m-100 m-p0 pr20">
          <PostContent data={data} />
        </div>
        <div className="w-50 m-100 m-p0 pl20">
          <PostImage image={data.featureImage} />
        </div>
      </div>
      <Cta />
    </Layout>
  )
}

export const Head = ({ data }) => <Seo title={data.post.title} />

export default Member

export const query = graphql`
  query PostQuery($id: String!) {
    post:datoCmsBlogPost(id: { eq: $id }) {
      title
      content
      pullQuote
      category
      featureImage {
        gatsbyImageData
      }
    }
  }
`
