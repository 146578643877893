import * as React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const PostImage = ({ image }) => (
  <div className="sticky">
    <div className="ratio-1-2 bg-grey pos-rel">
      <GatsbyImage
        className="bg-image"
        image={image?.gatsbyImageData}
        alt="Profile Image"
      />
    </div>
  </div>
)

export default PostImage
